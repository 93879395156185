import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "px-10 py-4 pb-8" }
const _hoisted_2 = { class: "flex items-center py-2" }
const _hoisted_3 = { class: "flex items-center justify-between gap-2 pt-2 w-full mb-4" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "shadow-lg rounded-lg relative bg-white" }
const _hoisted_6 = { class: "flex items-center justify-center space-x-2" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "flex-grow text-center w-full" }

import { computed, onMounted, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import BaseSpinner from "@/components/BaseSpinner.vue";
import { STATUS_TENANT } from "@ems/constants/account_registration_status";
import { ITenantParameters } from "@/models/Tenant";
import TenantUserModule from "@ems/containers/Tenant/TenantUser.module";
import { formatDateTime } from "@/utils/helpers/convertDateTime";
import { getEnumValue } from "@/utils/helpers/getEnumValue";
import TenantBadge from "@/components/Common/Status/TenantBadge.vue";
import SearchInput from "@/components/Common/SearchHelper/SearchInput.vue";
import { SEARCH_TEXT } from "@ems/locales/search";


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const filterOptions = computed(() => [
  "All",
  "Pending",
  "Approved",
  "Rejected",
]);

const disabled = ref(false);
const small = ref(true);
const currentPage = ref(1);
const pageSize = ref(10);
const activeFilter = ref("All");
const searchText = ref("");
const sortBy = ref("");
const sortOrder = ref(1);
const isSearch = ref(false);
const saveInputSearch = ref("");

const router = useRouter();
const route = useRoute();
const tenantAccountList = computed(
  () => TenantUserModule.dataGetListTenantGetter
);

const paginationGet = computed(() => TenantUserModule.paginationGetter);

const isLoadingDataListTenant = computed(
  () => TenantUserModule.isLoadingDataListTenantGetter
);

const handleClearSearch = () => {
  searchText.value = "";
};

const handleFilterStatus = (filter: string) => {
  activeFilter.value = filter;
  getTenantAccount({
    CompanyName: isSearch.value ? saveInputSearch.value : "",
    Status: activeFilter.value === "All" ? "" : activeFilter.value,
  });
};

const handleSizeChange = (val: number) => {
  currentPage.value = 1;
  pageSize.value = val;
  getTenantAccount({
    CompanyName: isSearch.value ? saveInputSearch.value : "",
    Status: activeFilter.value === "All" ? "" : activeFilter.value,
  });
};

const handleCurrentChange = (val: number) => {
  currentPage.value = val;
  getTenantAccount({
    CompanyName: isSearch.value ? saveInputSearch.value : "",
    Status: activeFilter.value === "All" ? "" : activeFilter.value,
  });
};

const handleSortChange = (event: any) => {
  sortBy.value = event.prop;
  sortOrder.value = event.order === "ascending" ? 0 : 1;
  getTenantAccount({
    CompanyName: isSearch.value ? saveInputSearch.value : "",
    Status: activeFilter.value === "All" ? "" : activeFilter.value,
  });
};

const handleDetailAccountProfile = (_: number, tenantId: string) => {
  router.push(`${route.path}/${tenantId}/detail`);
};

const handleSearchCompany = () => {
  isSearch.value = true;
  saveInputSearch.value = searchText.value.trim();
  currentPage.value = 1;
  if (saveInputSearch.value === "") {
    return;
  }
  getTenantAccount({
    CompanyName: saveInputSearch.value,
    Status: activeFilter.value === "All" ? "" : activeFilter.value,
  });
};

const getTenantAccount = (params?: ITenantParameters) => {
  TenantUserModule.getListTenantAction({
    ...params,
    CurrentPage: currentPage.value,
    Limit: pageSize.value,
    OrderBy: "CreatedDate",
    OrderByDirection: sortOrder.value ?? 1,
  });
};

watch(searchText, (newVal) => {
  if (newVal.trim() === "") {
    isSearch.value = false;
    saveInputSearch.value = "";
    getTenantAccount({
      Status: activeFilter.value === "All" ? "" : activeFilter.value,
    });
  }
});

onMounted(() => {
  getTenantAccount();
});

return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(SearchInput, {
        class: "w-full",
        modelValue: searchText.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchText).value = $event)),
        onSearch: handleSearchCompany,
        onClear: handleSearchCompany,
        placeholder: _unref(SEARCH_TEXT).search_by_company_name
      }, null, 8, ["modelValue", "placeholder"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filterOptions.value, (opt) => {
        return (_openBlock(), _createElementBlock("button", {
          class: _normalizeClass(["w-full transition-colors bg-white", ['filter-btn', { 'filter-btn-active': activeFilter.value === opt }]]),
          key: opt,
          onClick: _withModifiers(($event: any) => (handleFilterStatus(opt)), ["prevent"])
        }, _toDisplayString(opt), 11, _hoisted_4))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        class: "w-full table-custom px-4 overflow-y-auto",
        style: {
          borderRadius: '4px',
          borderColor: 'transparent',
          color: '#000000',
        },
        onSortChange: handleSortChange,
        data: tenantAccountList.value,
        "max-height": "66vh",
        stripe: "",
        "cell-class-name": "text-cafe-dark",
        "header-row-class-name": "tableHeaderRow",
        "row-class-name": "tableRow",
        "element-loading-text": "Loading...",
        "element-loading-background": "rgba(0, 0, 0, 0.6)"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            "min-width": 100,
            prop: "CompanyName",
            label: "Company Name"
          }),
          _createVNode(_component_el_table_column, {
            "min-width": 100,
            prop: "CompanyRegistrationNumber",
            label: "Registration Number"
          }),
          _createVNode(_component_el_table_column, {
            prop: "Country",
            label: "Country"
          }),
          _createVNode(_component_el_table_column, {
            prop: "Province",
            label: "Province"
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(scope.row.Province ? scope.row.Province : scope.row.Country), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "CreatedDate",
            sortable: "",
            label: "Created Date",
            "header-align": "right",
            align: "right"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("p", null, _toDisplayString(_unref(formatDateTime)(scope.row.CreatedDate)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "Status",
            label: "Status",
            "header-align": "center",
            align: "center"
          }, {
            default: _withCtx((scope) => [
              _createVNode(TenantBadge, {
                status: scope.row.Status
              }, null, 8, ["status"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "Action",
            label: "Details",
            "header-align": "center"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("button", {
                  type: "button",
                  onClick: _withModifiers(($event: any) => (
                  handleDetailAccountProfile(scope.$index, scope.row.TenantId)
                ), ["prevent"])
                }, _cache[3] || (_cache[3] = [
                  _createElementVNode("p", { class: "text-green-secondary font-bold" }, "View", -1)
                ]), 8, _hoisted_7)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"])), [
        [_directive_loading, isLoadingDataListTenant.value]
      ]),
      (tenantAccountList.value.length > 0)
        ? (_openBlock(), _createBlock(_component_el_pagination, {
            key: 0,
            currentPage: currentPage.value,
            "onUpdate:currentPage": _cache[1] || (_cache[1] = ($event: any) => ((currentPage).value = $event)),
            "page-size": pageSize.value,
            "onUpdate:pageSize": _cache[2] || (_cache[2] = ($event: any) => ((pageSize).value = $event)),
            "page-sizes": [10, 20, 30, 40],
            size: "small",
            disabled: disabled.value,
            background: false,
            layout: "sizes, slot, prev, pager, next",
            total: paginationGet.value.TotalRow,
            onSizeChange: handleSizeChange,
            onCurrentChange: handleCurrentChange,
            class: "px-4 py-3 flex items-center justify-between text-neutral-dark font-semibold"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("span", null, " Show " + _toDisplayString((currentPage.value - 1) * pageSize.value + 1) + " to " + _toDisplayString(Math.min(currentPage.value * pageSize.value, paginationGet.value.TotalRow)) + " of " + _toDisplayString(paginationGet.value.TotalRow) + " Items ", 1)
              ])
            ]),
            _: 1
          }, 8, ["currentPage", "page-size", "disabled", "total"]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})